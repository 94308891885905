<template>
    <div class="product">
        <template v-if="productInfo.deviceStatus === 'NOW_ON_SHELF'">
            <div class="product-main">
                <el-breadcrumb separator="/">
                    <el-breadcrumb-item :to="{ path: '/' }">{{ $t('shou-ye') }}</el-breadcrumb-item>
                    <el-breadcrumb-item :to="{ path: '/' }">{{ $t('hui-yuan-zong-lan') }}</el-breadcrumb-item>
                    <el-breadcrumb-item :to="{ path: '/brandDetail?id=' + productInfo.vendorId }">{{
                        $t('hui-yuan-zhu-ye')
                    }}</el-breadcrumb-item>
                    <el-breadcrumb-item>{{ $t('chan-pin-xiang-qing') }}</el-breadcrumb-item>
                </el-breadcrumb>

                <div class="product-top">
                    <product-img :img="productInfo.img" :video="productInfo.video"></product-img>

                    <product-top :productInfo="productInfo" :tags="tags"> </product-top>
                </div>
            </div>

            <el-container class="product-main">
                <el-aside width="200px">
                    <Product-related :productId="productId" />
                </el-aside>
                <el-main>
                    <el-popover placement="bottom" width="200" trigger="hover" v-if="pdf.length > 0">
                        <el-link slot="reference" :underline="false" class="pdf">
                            <img src="../../assets/xiangqing_icon_pdf.png" alt="" />
                            <span>{{ $t('pdf-zi-liao') }}</span>
                        </el-link>

                        <div class="pdf-info" v-for="(item, index) in pdf" :key="index">
                            <img src="../../assets/xiangqing_icon_pdf.png" alt="" />

                            <span> {{ item.name }}</span>

                            <el-button type="warning" size="mini" plain @click="downDload(item.url)">{{
                                $t('cha-kan')
                            }}</el-button>
                        </div>
                    </el-popover>

                    <el-tabs type="card" class="detailTab">
                        <el-tab-pane :label="$t('chan-pin-xiang-qing')">
                            <ProductDetailInfo :productInfo="productInfo" :tags="alltags"></ProductDetailInfo>
                        </el-tab-pane>
                        <el-tab-pane :label="$t('xiang-xi-can-shu')">
                            <ProductParameter :productId="productId"></ProductParameter>
                        </el-tab-pane>
                    </el-tabs>
                </el-main>
            </el-container>
        </template>

        <empty-page v-else-if="productInfo.id">
            <span slot="text">{{ $t('ci-shang-pin-zheng-zai-shen-he-zhong-zan-shi-wu-fa-cha-kan') }}</span>
        </empty-page>
    </div>
</template>
<script>
import { mapState } from 'vuex';
import ProductImg from '../../components/product/ImgZoom.vue';
import ProductTop from '../../components/product/ProductTop.vue';
import ProductRelated from '../../components/product/ProductRelated';

import ProductDetailInfo from '../../components/product/ProductDetailInfo.vue';
import ProductParameter from '../../components/product/ProductParameter';
import { saveBrowse, saveStatistics } from '../../utils/CommonApi';

export default {
    name: 'product',
    metaInfo() {
        return {
            title: this.pageName || this.$t('imt-ke-ji-ping-tai'), // set a title
            meta: [
                {
                    name: 'keywords',
                    content: this.pageMetaKey || this.$t('imt-ke-ji-ping-tai')
                }
            ]
        };
    },
    data() {
        return {
            productId: 0,
            productInfo: {
                img: ''
            },
            pageName: '',
            pageMetaKey: ''
        };
    },
    computed: {
        ...mapState(['userInfo']),
        title() {
            return this.$i18n.locale === 'zh'
                ? this.productInfo.chName
                : this.productInfo.enName || this.productInfo.chName;
        },
        tags() {
            return this.productInfo.tag || [];
        },
        pdf() {
            return this.productInfo.pdf1 ? this.productInfo.pdf1 : [];
        },
        alltags() {
            let list = [];
            if (this.productInfo.customTag) {
                list = this.getName(this.productInfo, ['customTag', 'enCustomTag']).split(',') || [];
            }
            return [...this.tags, ...list];
        }
    },
    watch: {
        $route(val, oldVal) {
            if (val.query.id) {
                this.productId = val.query.id;
                this.getDetail();
                document.documentElement?.scrollTo({
                    top: 0,
                    behavior: 'smooth'
                });
            }

            if (oldVal.query.id) {
                saveStatistics(oldVal.query.id, 'PRODUCT', true);
            }
        }
    },
    beforeRouteLeave(form, to, next) {
        saveStatistics(this.productId, 'PRODUCT', true);
        next();
    },
    mounted() {
        if (this.$route.query.id) {
            this.productId = this.$route.query.id;
            this.getDetail();
        }
        window.addEventListener('beforeunload', this.beforeunloadEvent);
    },
    methods: {
        beforeunloadEvent(e) {
            saveStatistics(this.productId, 'PRODUCT', true);
            // if (this.edit) {
            //     var e = window.event || e;
            //     e.returnValue = '确定离开当前页面吗？';
            // }
        },
        getDetail() {
            this.$http.get(`/product/getDto/${this.productId}`).then(res => {
                this.productInfo = res;
                this.$emit('setStore', res.vendorInfoId);

                if (this.userInfo) {
                    saveBrowse(this.productId);
                    saveStatistics(this.productId);
                }

                this.pageName = this.getName(res);
                this.pageMetaKey = [this.getName(res), res.brand, this.getName(res.productCategory)].join(',');
            });
        },
        downDload(url) {
            // let routeData = this.$router.resolve({ path: '/PDF', query: { file: url } });
            window.open(
                this.$baseUrl + (process.env.NODE_ENV === 'development' ? '/' : '') + 'pdf/web/viewer.html?file=' + url,
                '_blank'
            );
        }
    },
    components: {
        ProductImg,
        ProductTop,
        ProductRelated,
        ProductDetailInfo,
        ProductParameter
    }
};
</script>

<style lang="scss" scoped>
@import '../../styles/variables';
.product {
    background-color: #f5f7fa;
}

.product-main {
    @include center-content(410);

    .el-breadcrumb {
        padding: 30px 0;
    }

    box-sizing: content-box;
}

.product-top {
    display: flex;
    background-color: #fff;

    .product-info {
        position: relative;
        z-index: 1;
    }
    .productImg {
        position: relative;
        z-index: 2;
    }
}

.el-container {
    margin-top: 84px;
}

.el-main {
    padding: 0 0 0 20px;
    position: relative;
}

.pdf-info {
    display: flex;
    align-items: center;
    cursor: pointer;
    span {
        margin-left: 10px;
        flex-grow: 1;
    }

    &:hover {
        span {
            color: #ffa526;
        }
    }
}
.pdf-info + .pdf-info {
    margin-top: 15px;
}
</style>

<style lang="scss">
.detailTab {
    .el-tabs__header.is-top {
        margin-bottom: 0px;
        border-width: 0px;
    }
    .el-tabs__nav-wrap.is-top {
        background-color: #dcdfe6;
        margin-bottom: 0px;
    }
    &.el-tabs--card > .el-tabs__header .el-tabs__item {
        border-width: 0px;
        height: 56px;
        line-height: 56px;
        position: relative;
    }
    &.el-tabs--card > .el-tabs__header .el-tabs__item.is-active {
        background-color: #fff;
        &::before {
            content: '';
            height: 3px;
            background: #ffa526;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
        }
    }
    .el-tabs__nav.is-top {
        border-width: 0px;
    }
}

.pdf {
    position: absolute;
    right: 30px;
    z-index: 20;
    top: 18px;
    .el-link--inner {
        display: flex;
        align-items: center;
        img {
            width: 20px;
            height: 20px;
        }

        span {
            font-size: 14px;
            margin-left: 10px;
            color: #292c33;
            line-height: 20px;
        }
    }
}
</style>
